@charset "utf-8";

.scharea {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  .schrow1 {
    border-bottom: 1px solid #d0d0d0;
    height: 70px;
  }
  .schrow2 {
    border-bottom: 1px solid #d0d0d0;
    height: 45px;
  }
  .schrow3 {
    height: 45px;
  }
}

.schtitle {
  width: 170px;
  height: 45px;
  line-height: 45px;
  padding-left: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #505050;
  vertical-align: middle;
  float: left;
}

.he70 {
  line-height: 70px;
  line-height: 70px;
}

.schcont {
  float: left;
  width: 630px;
  margin: 5px 0;
  .schcontval {
    float: left;
    width: 115px;
    line-height: 30px;
  }
}

.listtop {
  margin-top: 30px;
  height: 30px;
  .listsub {
    font-size: 20px;
    font-weight: bolder;
    color: #969ca0;
    float: left;
    margin-right: 20px;
  }
}

.subon {
  color: #3b3b3b !important;
}

.fntblue {
  margin-top: 3px;
  color: #016ab9;
}
.offline_content {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-top : 15px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
.default-image {
  width: 122px;
  height: 122px;
  background-color: #f5f5f5;
  display: inline-block;
  margin-right: 15px;
}
.item-content-wrap {
  padding: 0px;
  position: absolute;
  top: 15px;
  left: 176px;
  width: 76%;
}
.item-content {
  width: 100%;
}

.item-header {
  width: 600px;
  position: relative;
  .item-header-wrap {
    display: inline-block;
    padding-bottom: 16px;
  }
  .item-header-title {
    font-size: 18px;
    color: #0054a7;
    font-weight: bold;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    word-wrap: normal;
    white-space: nowrap;
    display: inline-block;
    max-width: 340px;
    a {
      color: #0054a7;
    }
  }
  .label {
    vertical-align: top;
    margin-top: 2px;
    display: inline-block;
    span {
      display: inline-block;
      color: #fff;
      font-size: 11px;
      height: 16px;
      line-height: 16px;
      padding: 0px 2px;
    }
    .finish {
      background: #d7004c;
    }
    .soon {
      background: #009f74;
    }
    .new {
      background: #0054a7;
    }
    .sale_new {
      background: #0054a7;
    }
    .sale {
      background: #000;
    }
    .soldout {
      background: #f8c100;
      color: #000;
    }
    .end {
      background: #5a5a5a;
    }

  }
}
.item-header-border {
  height: 1px;
  width: 400px;
  background: #0054a7;
}
.item-body {
  padding-top: 14px;
  p {
      line-height: 17.5px;
      font-size: 12px;
      color: #000;
      span {
          font-size: 12px;
          font-weight: bold;
          color: #000000;
          display: inline-block;
          width: 97px;
      }
  }
}
.item-price-wrap {
  float: right;
  margin-right: 0px;
  margin-top: -10px;
  .item-price-con {
    border: 0px;
    font-size: 16px;
    font-weight: bold;
    color: #0054a7;
    padding-bottom: 9px;
  }
}
.item-price {
  width: 130px;
  border: 1px solid #0054a7;
  div {
      text-align: center;
      font-size: 16px;
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      color: #0054a7;
    a {
      color: #0054a7;
    }
  }
  .lecture_btn {
      background-color: #0054a7;
      color: #fff;
    a {
      color: #fff;
    }
  }
}
.item-footer {
  font-weight: bold;
}




.listdown {
  margin-top: -5px;
}

.listmain .listsub .rowteacher {
  height: 30px;
  border-left: 1px solid #ddd;
  padding: 3px 10px 0 10px;
  margin-top: 5px;
  float: left;
  .rowttxt {
    font-size: 11px;
    color: #979797;
  }
  .rowttxt2 {
    color: #303030;
  }
}

.popOffArea {
  padding-top: 8px;
  width: 940px;
  .submenu {
    position: fixed;
    top: 9px;
    left: 9px;
    width: 152px;
    height: 700px;
    z-index: 2;
    .menuli {
      margin-top: -1px;
    }
  }
  .submain {
    margin-left: 150px;
    width: 780px;
    border: 1px solid #bec6cf;
    border-right: 0px;
    z-index: 1;
    margin-bottom: 10px;
    position: relative;
    .subinfo {
      position: fixed;
      width: 740px;
      height: 155px;
      margin: 8px 19px;
      border: 1px solid #bec6cf;
      background-color: #faf9f7;
      top: 0px;
      left: 151px;
      left: 151px \9;
      z-index: 100;
    }
    .subinfo_bg {
      position: fixed;
      top: 0px;
      padding-top: 5px;
      z-index: 99;
      background-color: #fff;
      width: 100%;
      height: 160px;
    }
    .subcont {
      width: 740px;
      margin: 0 19px 19px 19px;
      padding-top: 155px;
      border: 1px solid #bec6cf;
      background-color: #faf9f7;
    }
    .subcont2 {
      width: 700px;
      padding: 20px;
      margin-bottom: 20px;
      margin-top: 160px;
    }
  }
}

.popExamOffTitle {
  height: 28px;
  width: 90px;
  font-size: 14px;
  font-weight: bold;
}

.popExamOffTxt {
  height: 28px;
}

.bb1 {
  border-bottom: 1px solid #ecebe9;
}